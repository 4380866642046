




import Vue from 'vue'

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'//threejs/r127/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'//threejs/r127/examples/jsm/loaders/OBJLoader.js';

export default {
    name: 'Viewer3D',
    data() {
        return {
            renderer: null,
            scene: null,
            camera: null,
            cameraDistance: 3000,
            controls: null,
            tabs: null,
            tabViewer: null,
            containerView: null,
            containerParent: null,
            viewDimensions: null,
            viewWidth: null,
            viewHeight: null,
            viewer3D: null,
            viewerId: null,
            viewParent: null,
            viewParentMaterialId: null,
            materialObject: null,
            appApiUrl: process.env.VUE_APP_API_URL,
            testObj: '',
            DZOOM: 1,
            aspect: 1,
            objectsLoad: [{ 'id': '' }, { 'object': '' }],
            objectFiles: [],
            objectLoader: '',
            loadedObject: '',
            file: '',
            objFileUri: '',
            objMaterialsUri: '',
            timberModel: null,
            concreteModel: null,
            steelModel: null,
            countDown: 5,
            manager: null,
            loader: null
        }
    },
    created() {
        this.$root.$on('changeViewOrientation', this.changeViewOrientation);
        window.addEventListener('resize', this.onWindowResize, { passive: true });
        
        this.$root.$on('clearMaterialObject', this.clearMaterialObject);
        this.$root.$on('obtainMaterialObject', this.obtainMaterialObject);
        this.$root.$on('saveCamera', this.saveCamera);
        this.$root.$on('restoreCamera', this.restoreCamera);
        
    },
    mounted() {
        this.define()
        this.countDownTimer()
    },
    methods: {
        setCamera: function (materialType) {
            if (materialType === 'Timber') {
                this.camera.position.set(this.$store.state.materials.cameraTimberSaved.position.x,
                    this.$store.state.materials.cameraTimberSaved.position.y,
                    this.$store.state.materials.cameraTimberSaved.position.z);
                this.camera.rotation.set(this.$store.state.materials.cameraTimberSaved.rotation.x,
                    this.$store.state.materials.cameraTimberSaved.rotation.y,
                    this.$store.state.materials.cameraTimberSaved.rotation.z);
                this.controls.target.set(this.$store.state.materials.cameraTimberSaved.target.x,
                    this.$store.state.materials.cameraTimberSaved.target.y,
                    this.$store.state.materials.cameraTimberSaved.target.z);
            } else if (materialType === 'Concrete') {
                this.camera.position.set(this.$store.state.materials.cameraConcreteSaved.position.x,
                    this.$store.state.materials.cameraConcreteSaved.position.y,
                    this.$store.state.materials.cameraConcreteSaved.position.z);
                this.camera.rotation.set(this.$store.state.materials.cameraConcreteSaved.rotation.x,
                    this.$store.state.materials.cameraConcreteSaved.rotation.y,
                    this.$store.state.materials.cameraConcreteSaved.rotation.z);
                this.controls.target.set(this.$store.state.materials.cameraConcreteSaved.target.x,
                    this.$store.state.materials.cameraConcreteSaved.target.y,
                    this.$store.state.materials.cameraConcreteSaved.target.z);
            } else if (materialType === 'Steel') {
                this.camera.position.set(this.$store.state.materials.cameraSteelSaved.position.x,
                    this.$store.state.materials.cameraSteelSaved.position.y,
                    this.$store.state.materials.cameraSteelSaved.position.z);
                this.camera.rotation.set(this.$store.state.materials.cameraSteelSaved.rotation.x,
                    this.$store.state.materials.cameraSteelSaved.rotation.y,
                    this.$store.state.materials.cameraSteelSaved.rotation.z);
                this.controls.target.set(this.$store.state.materials.cameraSteelSaved.target.x,
                    this.$store.state.materials.cameraSteelSaved.target.y,
                    this.$store.state.materials.cameraSteelSaved.target.z);
            }

            this.controls.update();
            this.camera.updateProjectionMatrix();
            this.animate();
        },
        saveCamera: function (source, action) {
            if (action === 'openDialog') {

                if (source.$attrs.parentId === 'cardTimber3D' && this.viewerId === 'viewerTimber3D') {
                    this.$store.state.materials.cameraTimberSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraTimberSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraTimberSaved.target = this.controls.target.clone()
                } else if (source.$attrs.parentId === 'cardConcrete3D' && this.viewerId === 'viewerConcrete3D') {
                    this.$store.state.materials.cameraConcreteSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraConcreteSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraConcreteSaved.target = this.controls.target.clone()
                } else if (source.$attrs.parentId === 'cardSteel3D' && this.viewerId === 'viewerSteel3D') {
                    this.$store.state.materials.cameraSteelSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraSteelSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraSteelSaved.target = this.controls.target.clone()
                }

            } else {    // closing
                if (source.$attrs.parentId === 'cardTimber3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'timberCard') {
                    this.$store.state.materials.cameraTimberSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraTimberSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraTimberSaved.target = this.controls.target.clone()
                } else if (source.$attrs.parentId === 'cardConcrete3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'concreteCard') {
                    this.$store.state.materials.cameraConcreteSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraConcreteSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraConcreteSaved.target = this.controls.target.clone()
                } else if (source.$attrs.parentId === 'cardSteel3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'steelCard') {
                    this.$store.state.materials.cameraSteelSaved.position = this.camera.position.clone()
                    this.$store.state.materials.cameraSteelSaved.rotation = this.camera.rotation.clone()
                    this.$store.state.materials.cameraSteelSaved.target = this.controls.target.clone()
                }
            }
        },
        restoreCamera: function (source, action) {
            if (action === 'openDialog') {

                if (source.$attrs.parentId === 'cardTimber3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'timberCard') {

                    if (this.$store.state.materials.timberMaterialObject !== null) {
                        const selectedObject = this.scene.getObjectByName("ActiveTimberObject");
                        if (selectedObject !== null) {
                            this.scene.remove(selectedObject);
                            this.scene.add(this.$store.state.materials.timberMaterialObject.clone());
                        }
                    }
                    this.setCamera('Timber')
                } else if (source.$attrs.parentId === 'cardConcrete3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'concreteCard') {

                    if (this.$store.state.materials.concreteMaterialObject !== null) {
                        const selectedObject = this.scene.getObjectByName("ActiveConcreteObject");
                        if (selectedObject !== null) {
                            this.scene.remove(selectedObject);
                            this.scene.add(this.$store.state.materials.concreteMaterialObject.clone());
                        }
                    }
                    this.setCamera('Concrete')
                } else if (source.$attrs.parentId === 'cardSteel3D' && this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === 'steelCard') {

                    if (this.$store.state.materials.steelMaterialObject !== null) {
                        const selectedObject = this.scene.getObjectByName("ActiveSteelObject");
                        if (selectedObject !== null) {
                            this.scene.remove(selectedObject);
                            this.scene.add(this.$store.state.materials.steelMaterialObject.clone());
                        }
                    }
                    this.setCamera('Steel')
                }
            } else {    // closing
                if (source.$attrs.parentId === 'cardTimber3D' && this.viewerId === 'viewerTimber3D') {
                    this.setCamera('Timber')
                } else if (source.$attrs.parentId === 'cardConcrete3D' && this.viewerId === 'viewerConcrete3D') {
                    this.setCamera('Concrete')
                } else if (source.$attrs.parentId === 'cardSteel3D' && this.viewerId === 'viewerSteel3D') {
                    this.setCamera('Steel')
                }
            }
        },
        define: function () {

            if (this.renderer === null) {
                this.manager = new THREE.LoadingManager();

                this.viewerId = this.$attrs.id;
                this.viewParent = this.$attrs.parentId;
                this.containerView = document.getElementById(this.viewerId);
                this.containerParent = document.getElementById(this.viewParent);

                this.scene = new THREE.Scene()

                this.containerParent = document.getElementById(this.$el.parentNode.id);
                if (this.containerParent.offsetWidth === 0) {
                    this.viewWidth = 500;
                    this.viewHeight = 500;
                } else {
                    this.viewWidth = this.containerParent.offsetWidth;
                }

                this.scene = new THREE.Scene()

                this.camera = new THREE.PerspectiveCamera(
                    1,
                    this.viewWidth / this.viewHeight,
                    5,
                    this.cameraDistance
                )
                
                this.scene.add(this.camera);
                this.scene.castShadow = true;

                var ambientLight = new THREE.AmbientLight(0xAAAAAA, 0.7);
                ambientLight.intensity = 0.7;
                this.scene.add(ambientLight);

                var backLight = new THREE.DirectionalLight(0xFFFFFF, 0.9, 100);
                backLight.position.set(20, 20, -20).normalize();
                this.scene.add(backLight);

                var pointLight = new THREE.PointLight(0xAAAAAA, 0.7, 70);
                pointLight.position.set(20, 20, 20);
                this.scene.add(pointLight);

                var pointLight2 = new THREE.PointLight(0xDDDDDD, 0.3, 30);
                pointLight2.position.set(20, -20, 20);
                this.scene.add(pointLight2);

                var pointLight3 = new THREE.PointLight(0xCCCCCC, 0.1, 10);
                pointLight3.position.set(-20, 20, -20);
                this.scene.add(pointLight3);

                var pointLight4 = new THREE.PointLight(0xEEEEEE, 0.5, 50);
                pointLight4.position.set(-20, -20, -20);
                this.scene.add(pointLight3);
                

                this.renderer = new THREE.WebGLRenderer({
                    alpha: true,
                    antialias: true
                });
                this.controls = new OrbitControls(this.camera, this.renderer.domElement);
                this.controls.listenToKeyEvents(window); // optional
                this.controls.enableRotate = true;
                this.controls.dampingFactor = 0.05;
                this.controls.screenSpacePanning = true;
                this.controls.maxPolarAngle = Math.PI;

                this.containerView.appendChild(this.renderer.domElement);
                
                // view doesn't exist until popuop is selected so have to call "obtainMaterialObject" first time round
                if (this.viewerId === 'viewerPopUp') {
                    this.viewParentMaterialId = this.containerView.parentNode.parentNode.parentNode.parentNode.parentNode.offsetParent.id;
                    if (this.viewParentMaterialId === 'timberCard') {
                        this.scene.add(this.$store.state.materials.timberMaterialObject.clone());
                        this.setCamera('Timber')
                    } else if (this.viewParentMaterialId === 'concreteCard') {
                        this.scene.add(this.$store.state.materials.concreteMaterialObject.clone());
                        this.setCamera('Concrete')
                    } else if (this.viewParentMaterialId === 'steelCard') {
                        this.scene.add(this.$store.state.materials.steelMaterialObject.clone());
                        this.setCamera('Steel')
                    }
                } else {
                    this.changeViewOrientation(this, 'isoView');
                }
            }

            this.onWindowResize();
        },
        calculateDimensions(_object) {

            var absoluteMinX = 0, absoluteMaxX = 0, absoluteMinY = 0, absoluteMaxY = 0, absoluteMinZ = 0, absoluteMaxZ = 0;

            for (var i = 0; i < _object.children.length; i++) {
                _object.children[i].geometry.computeBoundingBox();
                absoluteMinX = Math.min(absoluteMinX, _object.children[i].geometry.boundingBox.min.x);
                absoluteMaxX = Math.max(absoluteMaxX, _object.children[i].geometry.boundingBox.max.x);
                absoluteMinY = Math.min(absoluteMinY, _object.children[i].geometry.boundingBox.min.y);
                absoluteMaxY = Math.max(absoluteMaxY, _object.children[i].geometry.boundingBox.max.y);
                absoluteMinZ = Math.min(absoluteMinZ, _object.children[i].geometry.boundingBox.min.z);
                absoluteMaxZ = Math.max(absoluteMaxZ, _object.children[i].geometry.boundingBox.max.z);
            }

            // set generic height and width values
            _object.depth = (absoluteMaxX - absoluteMinX) * _object.scale.x;
            _object.height = (absoluteMaxY - absoluteMinY) * _object.scale.y;
            _object.width = (absoluteMaxZ - absoluteMinZ) * _object.scale.z;

            // remember the original dimensions
            if (_object.originalDepth === undefined) _object.originalDepth = _object.depth;
            if (_object.originalHeight === undefined) _object.originalHeight = _object.height;
            if (_object.originalWidth === undefined) _object.originalWidth = _object.width;

            return {
                "Depth": _object.depth
                , "Height": _object.height
                , "Width": _object.width
            };
        },
        addLines(object, theScene) {
            for (var i = 0; i < object.children.length; i++) {
                var objEdges = new THREE.EdgesGeometry(object.children[i].geometry)
                var objMesh = new THREE.LineSegments(objEdges, new THREE.LineBasicMaterial({ color: 0x888888 }))
                objMesh.name = object.name
                theScene.add(objMesh);
            }
        },
        animate: function () {
            requestAnimationFrame(this.animate)

            if (this.countDown > 0) {
                if (this.timberModel !== null) {
                    this.timberModel.rotation.y += (0.01 + (this.countDown / 500))                        
                }

                if (this.concreteModel !== null) {
                    this.concreteModel.rotation.y += (0.01 + (this.countDown / 500))
                }
                if (this.steelModel !== null) {
                    this.steelModel.rotation.y += (0.01 + (this.countDown / 500))
                }
            } else {
                if (this.timberModel !== null) {
                    this.timberModel.rotation.y = 0
                }
                if (this.concreteModel !== null) {
                    this.concreteModel.rotation.y = 0
                }
                if (this.steelModel !== null) {
                    this.steelModel.rotation.y = 0
                }
            }

            this.renderer.render(this.scene, this.camera)
            this.controls.update(); 
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        refresh3DView(parentVue) {
            switch (parentVue.$attrs.id) {
                case 'viewerDialog':
                    if (this.$attrs.parentId = parentVue.$attrs.parentId) {
                        this.onWindowResize()
                    }
                    break;
                case 'viewerDialog':
                    if (this.$attrs.parentId = parentVue.$attrs.parentId) {
                        this.onWindowResize()
                    }
                    break;
                case 'viewerDialog':
                    if (this.$attrs.parentId = parentVue.$attrs.parentId) {
                        this.onWindowResize()
                    }
                    break;
            }
        },
        onWindowResize() {

            switch (this.viewParent) {
                case 'cardTimber3D':
                case 'cardConcrete3D':
                case 'cardSteel3D':
                    // need to refresh variable for the view
                    this.containerParent = document.getElementById(this.viewParent)

                    this.viewWidth = this.containerParent.offsetWidth;
                    this.viewHeight = this.containerParent.offsetHeight;
                    break;
                default:
                    this.containerParent = this.containerView.parentNode;
                    this.viewHeight = this.containerParent.offsetHeight + 0;
                    this.viewWidth = this.containerParent.offsetWidth + 0;

                    break;

            }
            this.renderer.setSize(this.viewWidth, this.viewHeight);
            this.camera.aspect = this.viewWidth / this.viewHeight;

            this.camera.updateProjectionMatrix();
        },
        clearMaterialObject: async function (materialType, parentId) {
            if (this.viewerId === 'viewerTimber3D' && this.viewParent === parentId && materialType === 'Timber') {
                try {
                    await this.disposeObject("ActiveTimberObject");
                } finally {
                    this.$store.state.materials.timberMaterialObject = null;
                }
            } else if (this.viewerId === 'viewerConcrete3D' && this.viewParent === parentId && materialType === 'Concrete') {
                try {
                    await this.disposeObject("ActiveConcreteObject");
                } finally {
                    this.$store.state.materials.concreteMaterialObject = null;
                }
            } else if (this.viewerId === 'viewerSteel3D' && this.viewParent === parentId && materialType === 'Steel') {
                try {
                    await this.disposeObject("ActiveSteelObject");
                } finally {
                    this.$store.state.materials.steelMaterialObject = null;
                }
            }
        },
        obtainMaterialObject: function (materialType, parentId, viewOrientation) {

            this.objFileUri === ''

            if (this.viewerId === 'viewerTab3D') {
                return;
            }

            if (this.viewerId === 'viewerTimber3D' && this.viewParent === parentId && materialType === 'Timber') {
                this.objFileUri = this.$store.state.materials.timberObjectUri;

                if (viewOrientation !== '') {
                    this.changeViewOrientation(this, viewOrientation)
                }
            }
            else if (this.viewerId === 'viewerConcrete3D' && this.viewParent === parentId && materialType === 'Concrete') {
                this.objFileUri = this.$store.state.materials.concreteObjectUri;

                if (viewOrientation !== '') {
                    this.changeViewOrientation(this, viewOrientation)
                }
            } else if (this.viewerId === 'viewerSteel3D' && this.viewParent === parentId && materialType === 'Steel') {
                this.objFileUri = this.$store.state.materials.steelObjectUri;

                if (viewOrientation !== '') {
                    this.changeViewOrientation(this, viewOrientation)
                }
            } else {
                // put support for ViewerPopUp here - all three views in cards need to be maximized accordingly
                if (this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === parentId && materialType === 'Timber') {
                    this.objFileUri = this.$store.state.materials.timberObjectUri;
                } else if (this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === parentId && materialType === 'Concrete') {
                    this.objFileUri = this.$store.state.materials.concreteObjectUri;
                } else if (this.viewerId === 'viewerPopUp' && this.viewParentMaterialId === parentId && materialType === 'Steel') {
                    this.objFileUri = this.$store.state.materials.steelObjectUri;
                } else {
                    return
                }
            }
            if (this.objFileUri === '') {
                return;
            }
            this.objMaterialsUri = this.$store.state.materials.objMaterialsUri;
            var _this = this;

            try {
                var mtlLoader = new MTLLoader();
                var objLoader = new OBJLoader();
                mtlLoader.load(_this.objMaterialsUri, function (materials) {
                    materials.preload();
                    objLoader.setMaterials(materials);
                    objLoader.load(_this.objFileUri, (root) => {
                        _this.$store.state.materials.materialDimensions = _this.calculateDimensions(root);

                        root.matrixAutoUpdate = true;
                        root.position.x = 0;
                        // move object so insert in at the center of object - easier rotating and centering in view
                        root.position.y = _this.$store.state.materials.materialDimensions.Height / -2;
                        root.position.z = 0;
                        root.castShadow = false;
                        root.updateMatrix();

                        if (materialType === 'Timber') {
                            root.name = "ActiveTimberObject"
                            _this.$store.state.materials.timberMaterialObject = root;
                            _this.scene.add(_this.$store.state.materials.timberMaterialObject);
                            _this.timberModel = root;
                        } else if (materialType === 'Concrete') {
                            root.name = "ActiveConcreteObject"
                            _this.$store.state.materials.concreteMaterialObject = root;
                            _this.scene.add(_this.$store.state.materials.concreteMaterialObject);
                            _this.concreteModel = root;
                        } else if (materialType === 'Steel') {
                            root.name = "ActiveSteelObject"
                            _this.$store.state.materials.steelMaterialObject = root;
                            _this.scene.add(_this.$store.state.materials.steelMaterialObject);
                            _this.steelModel = root;
                        }

                        _this.scene.add(root);

                        var biggestDim = _this.getBiggestDim(root, _this.$store.state.materials.materialDimensions)

                        _this.zoomObjectPerspective(root, biggestDim)
                    }, function (xhr) { // called when loading is in progresses
                    }, function (error) {   // called when loading has errors

                        console.log('**** Failed Loading Object File: ' + _this.objFileUri);
                        console.log(error);
                    });
                });                    
            } catch (error) {
                console.log(error)
            } finally {

                this.animate()

                this.renderer.render(this.scene, this.camera)
            }
        },
        loadMaterials: function(materials, callback) {
            var textures_loaded = 0;
            for (var id in materials) {
                this.loader.load('models/' + materials[id].unique_id + '.jpg', function (t) {
                    t.magFilter = THREE.NearestFilter;
                    t.minFilter = THREE.LinearMipMapLinearFilter;

                    var re = /models\/(\d+)\.jpg/g;
                    var result = re.exec(t.image.currentSrc);

                    var material = materials.filter(function (obj) {
                        return obj.unique_id == result[1];
                    }).shift();

                    material.setTexture(t);
                    textures_loaded += 1;

                    if (textures_loaded == materials.length) {
                        callback("materialsLoaded");
                    }
                });
            }
        },
        disposeObject: async function(objectName) {
            var selectedObject = undefined;
            await Promise.resolve((async () => {
                while ((selectedObject = this.scene.getObjectByName(objectName)) !== undefined) {
                    await Promise.resolve(this.disposeChildren(selectedObject));
                    await Promise.resolve(this.scene.remove(selectedObject));
                }
            })());
        },
        async disposeChildren(object) {
            await Promise.resolve(object.traverse(async function (child) {
                if (child instanceof THREE.Mesh) {
                    await Promise.resolve(child.geometry.dispose());
                    child = undefined;
                }
            }))
        },
        assignTexture(root, materialType) {
            var texture = null;
            try {

                switch (materialType) {
                    case 'Timber':
                        texture = this.$store.state.materials.timberTexture;
                        if (texture === null) {
                            var textureLoader = new THREE.TextureLoader();
                            texture = textureLoader.load(this.$store.state.materials.timberTextureFile);
                            this.$store.state.materials.timberTexture = texture;
                        }
                        break;
                    case 'Concrete':
                        texture = this.$store.state.materials.concreteTexture;
                        if (texture === null) {
                            var textureLoader = new THREE.TextureLoader();
                            texture = textureLoader.load(this.$store.state.materials.concreteTextureFile);
                            this.$store.state.materials.concreteTexture = texture;
                        }
                        break;
                    case 'Steel':
                        texture = this.$store.state.materials.steelTexture;
                        if (texture === null) {
                            var textureLoader = new THREE.TextureLoader();
                            texture = textureLoader.load(this.$store.state.materials.steelTextureFile);
                            this.$store.state.materials.steelTexture = texture;
                        }
                        break;
                }
            } catch (error) {
                    console.log(error);
            } finally {
                if (texture !== null) {
                    root.traverse(function (child) {
                        if (child instanceof THREE.Mesh) {
                            var material = child.material.clone();
                            material.name = child.name

                            material.map = texture;
                            child.material = material;
                        }
                    })
                }
            }
            return root;
        },
        changeViewMaterial(materialChange) {
            try {
                if (this.viewerId === "viewerTab3D") {

                    if (this.$store.state.materials.activeMaterial === materialChange) {
                        return;
                    }

                    if (this.$store.state.materials.activeMaterialObject !== null) {
                        const selectedObject = this.scene.getObjectByName("ActiveObject");
                        if (selectedObject !== null) {
                            this.scene.remove(selectedObject);
                        }
                    }
                    this.$store.state.materials.activeMaterial = materialChange;
                    switch (materialChange) {
                        case 'Timber':
                            this.$store.state.materials.activeMaterialObject = this.$store.state.materials.timberMaterialObject.clone()
                            break;
                        case 'Concrete':
                            this.$store.state.materials.activeMaterialObject = this.$store.state.materials.concreteMaterialObject.clone()
                            break;
                        case 'Steel':
                            this.$store.state.materials.activeMaterialObject = this.$store.state.materials.steelMaterialObject.clone()
                            break;
                    }
                    this.$store.state.materials.activeMaterialObject.Name = "ActiveObject"
                    this.scene.add(this.$store.state.materials.activeMaterialObject);

                    this.animate();
                    this.controls.update();
                    this.onWindowResize();
                    this.renderer.render(this.scene, this.camera)
                }

            } catch (error) {
                console.log(error);
            } finally {
            }
        },
        changeViewOrientation(parentVue, propChange) {
            try {
                
                var currentMaterialObject = null

                if (parentVue.$attrs.id === 'viewerDialog') {
                    if (parentVue.$attrs.parentId === 'cardTimber3D' && (this.viewParentMaterialId === 'timberCard' || this.viewerId === 'viewerTimber3D')) {
                        currentMaterialObject = this.$store.state.materials.timberMaterialObject
                    } else if (parentVue.$attrs.parentId === 'cardConcrete3D' && (this.viewParentMaterialId === 'concreteCard' || this.viewerId === 'viewerConcrete3D')) {
                        currentMaterialObject = this.$store.state.materials.concreteMaterialObject
                    } else if (parentVue.$attrs.parentId === 'cardSteel3D' && (this.viewParentMaterialId === 'steelCard' || this.viewerId === 'viewerSteel3D')) {
                        currentMaterialObject = this.$store.state.materials.steelMaterialObject
                    } else {
                        return
                    }
                }
                else if (parentVue.$attrs.id === 'viewerTimber3D' && this.viewerId === 'viewerTimber3D') {
                    currentMaterialObject = this.$store.state.materials.timberMaterialObject
                } else if (parentVue.$attrs.id === 'viewerConcrete3D' && this.viewerId === 'viewerConcrete3D') {
                    currentMaterialObject = this.$store.state.materials.concreteMaterialObject
                } else if (parentVue.$attrs.id === 'viewerSteel3D' && this.viewerId === 'viewerSteel3D') {
                    currentMaterialObject = this.$store.state.materials.steelMaterialObject
                } else {
                    return
                }

                var biggestDim = this.getBiggestDim(currentMaterialObject, this.$store.state.materials.materialDimensions)

                switch (propChange) {
                    case 'topView':

                        // top view
                        this.camera.position.x = 0;
                        this.camera.position.y = biggestDim; //this.distanceToFit(this.camera, biggestValue);
                        this.camera.position.z = 0;
                        this.camera.lookAt(0, 0, 0);

                        break;
                    case 'bottomView':

                        // bottom view
                        this.camera.position.x = 0;
                        this.camera.position.y = -biggestDim;
                        this.camera.position.z = 0;
                        this.camera.lookAt(0, 0, 0);

                        break;
                    case 'frontView':

                        this.camera.position.x = 0;
                        this.camera.position.y = 0;
                        this.camera.position.z = biggestDim;
                        this.camera.lookAt(0, 0, 0);

                        break;
                    case 'sideView':

                        this.camera.position.x = biggestDim;
                        this.camera.position.y = 0;
                        this.camera.position.z = 0;
                        this.camera.lookAt(0, 0, 0);

                        break;
                    case 'isoView':

                        this.camera.position.x = biggestDim * -0.8;
                        this.camera.position.y = biggestDim * 2;
                        this.camera.position.z = biggestDim * -3;
                        this.camera.lookAt(0, this.$store.state.materials.materialDimensions.Height / 2.0, 0);
                        break;
                }

                this.zoomObjectPerspective(currentMaterialObject, biggestDim)

                this.animate()
                this.renderer.render(this.scene, this.camera)

            } catch (error) {
                console.log(error);
            } finally {
            }
        },
        getBiggestDim: function (materialObject, alternative) {
            var dimItem = materialObject
            if (dimItem === null) {
                dimItem = alternative
            }

            return Math.max(dimItem.width,
                dimItem.depth,
                dimItem.height)
        },
        mousewheel(event) {

            // focus on only the view that is being accessed
            if (this.viewerId !== event.target.parentElement.id) {
                return;
            }

            var factor = 50;
            var mX = (event.clientX / this.containerParent.offsetWidth) * 2 - 1;
            var mY = -(event.clientY / this.containerParent.offsetWidth) * 2 + 1;
            var vector = new THREE.Vector3(mX, mY, 0.5);
            //console.log(vector);
            vector.unproject(this.camera);
            vector.sub(this.camera.position);
            if (event.deltaY < 0) {
                this.camera.position.addVectors(this.camera.position, vector.setLength(factor));
                this.camera.updateProjectionMatrix();
            } else {
                this.camera.position.subVectors(this.camera.position, vector.setLength(factor));
                this.camera.updateProjectionMatrix();
            }               
        },
        pointCameraTo(node) {
            var bbox = new THREE.Box3().setFromObject(node);
            if (bbox.isEmpty()) {
                return;
            }
            try {
                // Refocus camera to the center of the new object                    
                var mdlen = bbox.max.x - bbox.min.x;
                var mdwid = bbox.max.z - bbox.min.z;
                var mdhei = bbox.max.y - bbox.min.y;
                var centerpoint = new THREE.Vector3();
                centerpoint.x = bbox.min.x + mdlen / 2;
                centerpoint.y = bbox.min.y + mdhei / 2;
                centerpoint.z = bbox.min.z + mdwid / 2;

                var v = new THREE.Vector3();
                v.subVectors(centerpoint, this.controls.target);

                this.camera.position.addVectors(this.camera.position, v);

                // retrieve camera orientation and pass it to trackball
                this.camera.lookAt(centerpoint);
                this.controls.target.set(centerpoint.x, centerpoint.y, centerpoint.z);
            } catch (error) {
                console.log(error)
            }

        },
        zoomObjectPerspective(node, size) {

            if (node === null) {
                return
            }
            var bbox = new THREE.Box3().setFromObject(node);
            if (bbox.isEmpty()) {
                return;
            }
            try {
                this.pointCameraTo(node);

                // the smaller FOV the more room along edge of view from object
                var distToCenter = size / Math.sin(Math.PI / 180.0 * this.camera.fov * 0.75);

                // move the camera backward
                var target = this.controls.target;

                var vec = new THREE.Vector3();
                vec.subVectors(this.camera.position, target);

                vec.setLength(distToCenter);
                this.camera.position.addVectors(vec, target);

                this.camera.updateProjectionMatrix();
            }
            catch (error) {
                console.log(error)
            } finally {

            }
        },
        distanceToFit(theCamera, objectSize) {
            // Convert camera fov degrees to radians
            var fov = theCamera.fov * (Math.PI / 180);

            // Calculate the camera distance
            return Math.abs(objectSize / Math.sin(fov / 2));
        },
        setFOV(theCamera) {
            theCamera.fov = 2 * Math.atan(window.innerHeight / (2 * this.cameraDistance)) * 180 / Math.PI;
        },
        zoomObjectOrtho(node) {
            var bbox = new THREE.Box3().setFromObject(node);
            if (bbox.isEmpty()) {
                return;
            }
            try {
                this.pointCameraTo(node);

                var size = new THREE.Vector3();
                bbox.getSize(size);
                var sphereSize = size.length() * 0.5;

                var distToCenter = 10

                // move the camera backward

                var target = this.controls.target;
                var vec = new THREE.Vector3();
                vec.subVectors(this.camera.position, target);
                vec.setLength(distToCenter);
                this.camera.position.addVectors(vec, target);
                this.camera.updateProjectionMatrix();
            } catch (error) {
                console.log(error)
            }
        }
    }
}
